import React from 'react';
import styles from './Notification.module.css';
import Link from 'next/link';
import {
    CashPurchaseNotification,
    ClaimNotification,
    NotificationToDisplay,
    OrderNotification,
    ReopenNotification,
} from '../../context/NotificationContext';
import moment from 'moment';
import {
    CashPurchaseNotificationStatus,
    NotificationSource,
    ReOpenSource,
} from '../../apiClient/generated';
import ArrowIcon from '../../public/gfx/uiIcons/goBackArrowIcon.svg';
import { DateBadge } from '../DateBadge/DateBadge';
import { useRouter } from 'next/router';

interface Props {
    notification: NotificationToDisplay;
}

const Notification: React.FC<Props> = ({ notification }) => {
    const { asPath } = useRouter();
    const getMessageForOrderNotification = (
        notification: OrderNotification,
    ) => {
        const { wbPono, notificationType } = notification;

        const orderIdSpan = <span className={styles.bold}>{wbPono}</span>;

        switch (notificationType) {
            case 2:
                return <>Your order {orderIdSpan} was sent to Garrets</>;
            case 3:
                return (
                    <>
                        Your order {orderIdSpan} was returned from Garrets for
                        your review
                    </>
                );
            case 4:
                return <>Your order {orderIdSpan} was sent to Garrets</>;
            case 5:
                return <>Garrets has confirmed your order {orderIdSpan}</>;
            case 7:
                return (
                    <>Your final order {orderIdSpan} was submitted to Garrets</>
                );
            case 8:
                return (
                    <>Your order {orderIdSpan} was cancelled at your request</>
                );
            case 9:
                return <>Your order {orderIdSpan} was cancelled by Garrets</>;
            default:
                return null;
        }
    };

    const getMessageForReopenNotification = (
        notification: ReopenNotification,
    ) => {
        const monthAsMoment = moment(notification.reportMonth, 'YYYY-MM');

        if (!monthAsMoment.isValid()) {
            return null;
        }

        const monthString = monthAsMoment.format('MMMM');

        const getReportType = () => {
            switch (notification.reOpenSource) {
                case ReOpenSource.Condemned:
                    return 'condemn';
                case ReOpenSource.Manning:
                    return 'manning';
                case ReOpenSource.Stocktaking:
                    return 'stocktaking';
            }
        };

        return (
            <>
                Your{' '}
                <span className={styles.bold}>
                    {monthString} {getReportType()} report
                </span>{' '}
                has been <span className={styles.bold}>reopened</span> by
                Garrets due to an issue and is awaiting your action.
            </>
        );
    };

    const getMessageForCashPurchaseNotification = (
        notification: CashPurchaseNotification,
    ) => {
        switch (notification.cashPurchaseStatus) {
            case CashPurchaseNotificationStatus.Updated:
                return (
                    <>
                        Garrets has made some changes to
                        <span className={styles.bold}>
                            &nbsp;Cash Purchase {notification.wbPono}
                        </span>
                        , no action is required.
                    </>
                );
            case CashPurchaseNotificationStatus.Cancelled:
                return (
                    <>
                        Garrets has cancelled your
                        <span className={styles.bold}>
                            &nbsp; Cash Purchase {notification.wbPono}
                        </span>
                        .
                    </>
                );
        }
    };

    const getMessageForClaimNotification = (
        notification: ClaimNotification,
    ) => {
        return (
            <>
                Your
                <span className={styles.bold}>
                    {/*TODO: add claim itemName (BE + FE)*/}
                    &nbsp;Claim from order #{notification.wbPono}
                </span>
                &nbsp;has been
                <span className={styles.bold}>&nbsp;completed</span> by Garrets.
                View claim to see outcome.
            </>
        );
    };

    const getMessageForNotification = (notification: NotificationToDisplay) => {
        switch (notification.source) {
            case NotificationSource.Order:
                return getMessageForOrderNotification(notification);
            case NotificationSource.Reopen:
                return getMessageForReopenNotification(notification);
            case NotificationSource.CashPurchase:
                return getMessageForCashPurchaseNotification(notification);
            case NotificationSource.ItemIssue:
                return getMessageForClaimNotification(notification);
        }
    };

    const getHrefForNotification = (notification: NotificationToDisplay) => {
        const getReportLink = (reOpenSource: ReOpenSource) => {
            switch (reOpenSource) {
                case ReOpenSource.Manning:
                    return 'manning';
                case ReOpenSource.Condemned:
                case ReOpenSource.Stocktaking:
                    return 'stocktaking';
            }
        };

        switch (notification.source) {
            case NotificationSource.Order:
                return `/order/${notification.type?.toLowerCase()}/${
                    notification.orderId
                }/summary/details`;

            case NotificationSource.Reopen:
                return `${getReportLink(notification.reOpenSource)}/${
                    notification.reportMonth
                }/${
                    notification.reOpenSource === ReOpenSource.Manning
                        ? '01'
                        : ''
                }`;
            case NotificationSource.CashPurchase:
                return `/order/cash/${notification.cashPurchaseId}/details?previous=${asPath}`;
            case NotificationSource.ItemIssue:
                return `/claims?itemIssueId=${notification.itemIssueId}`;
        }
    };

    const getNotificationButton = (notfication: NotificationToDisplay) => {
        const getText = () => {
            switch (notfication.source) {
                case NotificationSource.Reopen:
                    return 'View Report';
                case NotificationSource.ItemIssue:
                    return 'View Claim';
                default:
                    return '';
            }
        };
        const text = getText();
        return (
            text && (
                <div className={styles.callToActionLabel}>
                    <span>{text}</span>
                    <ArrowIcon stroke="white" className={styles.arrowIcon} />
                </div>
            )
        );
    };

    const message = getMessageForNotification(notification);
    const button = getNotificationButton(notification);

    if (!message) {
        return null;
    }

    return (
        <Link href={getHrefForNotification(notification)}>
            <a className={styles.container}>
                <div className={styles.row}>
                    <p className={styles.notificationText}>{message}</p>
                    <DateBadge
                        date={new Date(notification.created)}
                        displayTime
                    />
                </div>
                {button}
            </a>
        </Link>
    );
};

export default Notification;
